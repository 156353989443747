import React from "react";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import { useHandleTermsModal } from "@/lib/features/slices/paymentMethodSlice";
import { setTermsCheckBoxValue } from "@/lib/features/slices/TermsModalSlice";

const SignUpCheckBoxes = () => {
  const store = useAppStore();
  const { acceptTermsCond } = useAppSelector((state) => state.termsModal);
  const { values, errors } = useAppSelector((state) => state.registrationForm);

  return (
    <div className="accept-terms mt-5">
      {/* <div className="flex gap-2 mb-5">
        <input
          type="checkbox"
          checked={acceptEmails}
          name="acceptEmails"
          onChange={(e) =>
            store.dispatch(
              setEmailsCheckBoxValue({
                name: "acceptEmails",
                value: !acceptEmails,
              })
            )
          }
        />
        <p>Send me emails</p>
      </div> */}

      <div className="flex gap-2">
        <input
          className={`${errors.acceptTermsCond && "error-text"}`}
          autoComplete="off"
          type="checkbox"
          checked={acceptTermsCond}
          name="checked"
          onChange={(e) =>
            store.dispatch(
              setTermsCheckBoxValue({
                name: "acceptTermsCond",
                value: !acceptTermsCond,
              })
            )
          }
        />
        <p
          className={`${
            errors.acceptTermsCond && !acceptTermsCond && "error-text"
          }`}
        >
          Yes, I understand and agree with the {/* <br /> */}
          <span
            onClick={useHandleTermsModal()}
            className={`${
              errors.acceptTermsCond && !acceptTermsCond && "error-text"
            }`}
          >
            Terms and Conditions
          </span>
        </p>
      </div>
    </div>
  );
};

export default SignUpCheckBoxes;
