import { incremented } from "@/lib/features/slices/screensSlice";
import { useAppDispatch } from "@/lib/hooks";
import React from "react";

const WelcomeScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <>
      <div className="logo-navbar">
        <img src="/images/micromojo.svg" alt="first-frame" />
      </div>
      <div className="upper-frame">
        <img
          className="first-frame"
          src="/images/photoOne.png"
          alt="first-frame"
        />
        <img
          className="second-frame"
          alt="second-frame"
          src="/images/photoTwo.png"
        />
      </div>
      <div className="welcome-screen-box home-info">
        <img alt="love-game-title" src="/images/play_the_lovegame.svg" />
        <p className="love-game-desc">
          Embark on a 10-day journey, <br /> meticulously crafted to rekindle
          the <br />
          spark in your relationship
        </p>
        <button className="buy-btn" onClick={() => dispatch(incremented())}>
          Buy Lovegame
        </button>
      </div>
      <div className="lower-frame">
        <img
          className="third-frame"
          alt="third-frame"
          src="/images/photoThree.png"
        />
        <img
          className="fourth-frame"
          alt="fouth-frame"
          src="/images/photoFour.png"
        />
      </div>
    </>
  );
};

export default WelcomeScreen;
