import React from "react";
import Footer from "../components/footer";

const PartnerScreen = () => {
  return (
    <div className="pre-payment-screen wrapper">
      <div className="content">
        <h1>Great news!</h1>
        <h2>
          The mobile number you provided has already been registered in our Love
          Game app, likely by your partner.
          <br /> <br /> In the next couple of days, you'll receive a special
          invitation to join the game
        </h2>
        <span>
          Keep an eye on your SMS notifications, and get ready for an exciting
          experience!
        </span>
      </div>
      <Footer singleBtnLight="Got it" />
    </div>
  );
};

export default PartnerScreen;
