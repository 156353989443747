import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import {
  setTermsCheckBoxValue,
  showModal,
} from "@/lib/features/slices/TermsModalSlice";

export default function TermsModal() {
  const store = useAppStore();
  const modalData = useAppSelector((state) => state.termsModal);
  const cancelButtonRef = useRef(null);
  const { acceptTermsCond } = useAppSelector((state) => state.termsModal);
  return (
    <Transition.Root show={modalData.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 w-screen "
        style={{ backgroundColor: "#FFFAFA" }}
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <div className="flex items-start justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg text-center  transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="termsModal center-page sm:flex sm:items-start">
                <div className="mt-3 text-left sm:ml-4 sm:mt-0 sm:text-left">
                  <div
                    className="close-screen-icon"
                  >
                    <img onClick={() => store.dispatch(showModal(false))} src={"./images/Exit_icon_buton.svg"} />
                  </div>
                  <Dialog.Title as="h3" className="termsTitle">
                    Terms & Condition
                  </Dialog.Title>

                  <div className="terms-body">
                    <strong>Your Privacy Choices:</strong>

                    <ul>
                      <li>
                        You have the option to accept or decline our privacy
                        policy when first using the application.
                      </li>
                      <li>
                        You can update your personal information or withdraw
                        consent at any time by contacting us.
                      </li>
                    </ul>

                    <strong>Security:</strong>
                    <ul>
                      <li>
                        We take appropriate measures to protect your personal
                        information and ensure its confidentiality.
                      </li>
                    </ul>

                    <strong>Changes to This Policy:</strong>
                    <ul>
                      <li>
                        We may update this privacy policy periodically, and any
                        changes will be reflected on this page.
                      </li>
                    </ul>

                    <strong>Retention Period:</strong>
                    <ul>
                      <li>
                        We will retain your personal information for as long as
                        necessary to ful fill the purposes outlined in this
                        privacy policy, unless a longer retention period is
                        required or permitted by law.
                      </li>
                    </ul>
                    <strong>
                      By using our web application, you agree to the terms
                      outlined in this Privacy Policy.
                    </strong>
                  </div>
                </div>
              </div>

              <button
                onClick={() =>
                  store.dispatch(
                    setTermsCheckBoxValue({
                      name: "acceptTermsCond",
                      value: !acceptTermsCond,
                    }),
                    store.dispatch(showModal(false))
                  )
                }
                className="footer-single-btn-dark"
              >
                <p>Accept</p>
              </button>
            </Dialog.Panel>
          </Transition.Child>
          <div
            className="w-1/3 bg-#96009966"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: -1,
            }}
          >
            {/* Slider content */}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
