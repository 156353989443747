import React, { useEffect, useRef, useState } from "react";
import {
  handleResendCode,
  setOTPValue,
  useHandlePhoneForm,
} from "@/lib/features/slices/phoneSlice";
import { useAppDispatch, useAppSelector } from "@/lib/hooks";

import CountDownTimer from "./countDownTimer";
import { useHandleLoginVerify } from "@/lib/features/slices/registrationFormSlice";

const VerificationForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { codeError, isDisabled } = useAppSelector(
    (state) => state.phoneFormScreen
  );
  const maxPinLength = 4; // change as per your requirements

  const [password, setPassword] = useState<Array<string | number>>(
    Array(maxPinLength).fill("")
  );
  const inpRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [activeInput, setActiveInput] = useState(-1);

  const handlePaste = (e: React.ClipboardEvent, index: number) => {
    e.preventDefault(); // Prevent the default paste action
    const pasteData = e.clipboardData.getData("text");
    // Check if the pasted data is numeric and matches the maxPinLength
    const pasteDataArray = pasteData
      .split("")
      .filter((char) => !isNaN(Number(char)));
    if (pasteDataArray.length === maxPinLength) {
      const newPass = pasteDataArray.map(Number);
      setPassword(newPass);
      dispatch(setOTPValue({ name: "code", value: newPass.join("") }));
      // Focus the next input after the last pasted input if within range
      const nextInputIndex = index + pasteDataArray.length;
      if (nextInputIndex < maxPinLength) {
        inpRefs.current[nextInputIndex]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, i: number) => {
    if (e.key === "Backspace") {
      e.preventDefault(); // Prevent the default backspace behavior
      let pass = [...password]; // Create a shallow copy of the password array

      if (pass[i] !== "") {
        // If the current cell is not empty, clear it
        pass[i] = "";
        setPassword(pass);
      } else if (i > 0) {
        // If the current cell is empty and there's a previous cell, clear and focus the previous cell
        pass[i - 1] = "";
        setPassword(pass);
        setActiveInput(i - 1);
        inpRefs.current[i - 1]?.focus();
        return; // Return early to avoid focusing the current cell again below
      }

      // Set focus back to the current cell if it was not empty or there's no previous cell to focus
      setActiveInput(i);
      inpRefs.current[i]?.focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    const value = e.target.value;
    let pass = [...password];
    if (!isNaN(Number(value))) {
      pass[i] = Number(value);
      setPassword(pass);
      dispatch(setOTPValue({ name: "code", value: pass.join("") }));
      const nextInputIndex = i + 1;
      if (nextInputIndex < maxPinLength) {
        inpRefs.current[nextInputIndex]?.focus();
      }
    }
  };

  function ensureHasSingleDigit(
    e: React.FormEvent<HTMLInputElement>,
    i: number
  ) {
    if (i === 0) {
      return;
    }

    const target = e.target as HTMLInputElement;
    if (target.value.length > 1) {
      target.value = target.value[0];
      handleChange(e as unknown as React.ChangeEvent<HTMLInputElement>, i);
    }
  }
  const handleLoginVerify = useHandleLoginVerify();

  const validateArray = (password: any) => {
    // Check if the array length is exactly 4
    if (password.length !== 4) {
      return false;
    }

    // Iterate through each element in the array
    for (let i = 0; i < password.length; i++) {
      // Check if the element is an empty string or not a digit
      if (password[i] === "" || isNaN(password[i])) {
        return false;
      }
    }

    // If all conditions pass, return true
    return true;
  };

  useEffect(() => {
    if (validateArray(password)) {
      handleLoginVerify();
    }
  }, [password]);

  const handlePhoneForm = useHandlePhoneForm();

  return (
      <>
          <div className="box-center">
              <div>
                  <p className="enter-code">Enter code</p>
                  <form
                      className="char-boxes"
                      ref={(el) => {
                          inpRefs.current = el
                              ? Array.from(el.children).map(
                                    (child) =>
                                        child.firstChild as HTMLInputElement | null
                                )
                              : [];
                      }}
                  >
                      {password?.map((digit, i) => (
                          <div key={i}>
                              <input
                                  autoComplete="off"
                                  onFocus={() => setActiveInput(i)}
                                  onBlur={() => setActiveInput(-1)}
                                  onInput={(e) => ensureHasSingleDigit(e, i)}
                                  onKeyDown={(e) => handleKeyDown(e, i)}
                                  onChange={(e) => handleChange(e, i)}
                                  onPaste={
                                      i === 0
                                          ? (e) => handlePaste(e, i)
                                          : undefined
                                  }
                                  className={`${
                                      digit !== "" ? "input-has-value " : ""
                                  }${codeError ? "code-error" : ""}`}
                                  // style={{
                                  //     color: codeError ? "red" : undefined,
                                  //     borderColor: codeError
                                  //         ? "red"
                                  //         : undefined,
                                  // }}
                                  id={`pin_${i}`}
                                  type="text"
                                  pattern={"[0-9]*"}
                                  inputMode="numeric"
                                  value={digit !== "" ? digit : ""}
                              />
                          </div>
                      ))}
                  </form>
                  {codeError && (
                      <p className="code-error">Wrong code, please try again</p>
                  )}
              </div>
          </div>
          {!isDisabled && (
              <button
                  className={`resend-code ${isDisabled ? "disabled" : ""}`}
                  onClick={() => dispatch(handleResendCode())}
                  disabled={isDisabled}
              >
                  Didn’t get a code? <span>Click to resend</span>
              </button>
          )}

          {isDisabled && <CountDownTimer />}
      </>
  );
};

export default VerificationForm;
