import React from "react";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import { setFormValue } from "@/lib/features/slices/registrationFormSlice";
import { FormValues } from "@/lib/types/types";
import TermsModal from "./termsModal";
import CustomModal from "./customModal";

const SignUpForm: React.FC = () => {
  const store = useAppStore();
  const { values, errors } = useAppSelector((state) => state.registrationForm);
  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    store.dispatch(setFormValue({ name: name as keyof FormValues, value }));
  };

  return (
    <>
      <TermsModal />
      {/* <CustomModal/> */}
      <form className="form-center">
        {/* ___________________________________________________________________________ */}

        <label>First name</label>
        <input
          autoComplete="off"
          className={`input-field ${errors.firstName && "input-error"}`}
          id="name"
          type="text"
          name="firstName"
          placeholder="Ann"
          value={values.firstName}
          onChange={handleFieldChange}
          style={{ color: errors.firstName && "red" }}
        />
        <p className="error-text-validation">{errors.firstName}</p>
        {/* <img src={"/images/help-circle.png"} /> */}
        {/* ___________________________________________________________________________ */}

        <label>Surname</label>
        <input
          autoComplete="off"
          className={`input-field ${errors.surname && "input-error"}`}
          type="text"
          name="surname"
          placeholder="Brown"
          value={values.surname}
          onChange={handleFieldChange}
          style={{ color: errors.surname && "red" }}
        />
        <p className="error-text-validation">{errors.surname}</p>
        {/* ___________________________________________________________________________ */}
        <label>E-mail</label>
        <input
          autoComplete="off"
          className={`input-field ${errors.email && "input-error"}`}
          type="email"
          name="email"
          placeholder="hello@micro-mojo.com"
          value={values.email}
          onChange={handleFieldChange}
          style={{ color: errors.email && "red" }}
        />
        <p className="error-text-validation">{errors.email}</p>
        {/* ___________________________________________________________________________ */}

        <label>Confirm e-mail</label>
        <input
          autoComplete="off"
          className={`input-field ${errors.confirmEmail && "input-error"}`}
          type="email"
          placeholder="hello@micro-mojo.com"
          name="confirmEmail"
          value={values.confirmEmail}
          onChange={handleFieldChange}
          style={{ color: errors.confirmEmail && "red" }}
        />
        <p className="error-text-validation">{errors.confirmEmail}</p>
      </form>
    </>
  );
};

export default SignUpForm;
