import React from "react";
import LogoHeader from "../components/logoHeader";
import VerificationForm from "../components/verificationForm";

const VerificationCode = () => {
  return (
    <div className="center-page">
      <LogoHeader />
      <VerificationForm />
    </div>
  );
};

export default VerificationCode;
