import React from "react";
import Footer from "../components/footer";

const PrePayment = () => {
  return (
    <div className="pre-payment-screen wrapper">
      <div className="content">
        <h1>
          Unlock Your <br />
          Potential!
        </h1>
        <h2>
          Explore self-discovery and engaging challenges for meaningful changes
          in life and relationships. By purchasing LoveGame, you're investing in
          the best companion for your journey!
        </h2>
        <h3>
          Cost: <span>€200 for 2 persons</span>
        </h3>
      </div>
      <Footer singleBtnLight="Go to payment" />
    </div>
  );
};

export default PrePayment;
