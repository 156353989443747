import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";

const Congratulation = () => {
  return (
    <div className="wrapper">
      <div className="congratulation-screen content">
        <div className="center-page">
          <LogoHeader />
          <div className="title-header">
            <p className="page-title">Congratulations</p>
          </div>
          <div className="box-center !justify-start pt-10">
            <p className="text-left" style={{ fontSize: "18px" }}>
              Congratulations on taking the first step towards enhancing your
              life.
              <br /> <br />
              We invite you to experience our Lovegame universe, crafted to
              offer you a unique journey. <br /> <br />
              Kindly follow the link to the App Store to download our exclusive
              app. Wishing you an enriching experience. <br /> <br />
              Please click below to be directed to the App Store.
            </p>
          </div>
        </div>
        <Footer singleBtnDark="Go to the App Store" />
      </div>
    </div>
  );
};

export default Congratulation;
