import { setIsDisabled } from "@/lib/features/slices/phoneSlice";
import { useAppDispatch, useAppSelector, useAppStore } from "@/lib/hooks";
import { useState, useEffect } from "react";

const CountdownTimer = () => {
  const [remainingTime, setRemainingTime] = useState(120);
  const store = useAppStore();
  const dispatch = useAppDispatch();
  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          return prevTime;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  if (remainingTime == 0) {
    dispatch(setIsDisabled());
  }
  return (
    <div className="resend-code flex gap-2">
      <p>Send code again</p>
      <h4>
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </h4>
    </div>
  );
};

export default CountdownTimer;
