import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";
import SignUpForm from "../components/signUpForm";
import SignUpCheckBoxes from "../components/signUpCheckBoxes";

const Signup = () => {
  return (
    <div className="p-5 wrapper">
      <div className="content">
        <LogoHeader />
        <SignUpForm />
        <SignUpCheckBoxes />
      </div>
      <Footer leftBtnText={"Back"} rightBtnText={"Next"} />
    </div>
  );
};

export default Signup;
